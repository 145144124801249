import React, { useState } from "react";
import { Navbar } from "../components";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    service: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(
      "https://script.google.com/macros/s/AKfycbzzs4XP2Y7q5vGaq4uoILoyviOK8kiRPiJgwywpfzD3QehSSI-B7lEuhi7xsS2vN2d2/exec",
      {
        method: "POST",
        mode: "no-cors",
        body: new URLSearchParams(formData),
      }
    )
      .then(() => {
        alert("Form submitted successfully!");
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          service: "",
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("There was a problem with the form submission.");
      });
  };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-[#0D0D0D] gap-8">
      <Navbar />
      <form
        id="contact-form"
        className="w-full max-w-lg rounded-lg bg-gray-800 p-8"
        onSubmit={handleSubmit}
      >
        <div className="mb-4">
          <label
            className="mb-2 block text-sm font-bold text-white"
            htmlFor="first-name"
          >
            First Name
          </label>
          <input
            className="h-[60px] w-full rounded-lg border border-white bg-transparent px-3 text-white"
            id="first-name"
            name="firstName"
            type="text"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="mb-2 block text-sm font-bold text-white"
            htmlFor="last-name"
          >
            Last Name
          </label>
          <input
            className="h-[60px] w-full rounded-lg border border-white bg-transparent px-3 text-white"
            id="last-name"
            name="lastName"
            type="text"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="mb-2 block text-sm font-bold text-white"
            htmlFor="email"
          >
            Email
          </label>
          <input
            className="h-[60px] w-full rounded-lg border border-white bg-transparent px-3 text-white"
            id="email"
            name="email"
            type="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="mb-2 block text-sm font-bold text-white"
            htmlFor="phone"
          >
            Phone Number
          </label>
          <input
            className="h-[60px] w-full rounded-lg border border-white bg-transparent px-3 text-white"
            id="phone"
            name="phone"
            type="tel"
            placeholder="Phone Number"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="mb-2 block text-sm font-bold text-white"
            htmlFor="service"
          >
            Please select the service you are after
          </label>
          <div className="flex flex-col text-white">
            <label className="mb-2 inline-flex items-center">
              <input
                type="radio"
                className="form-radio text-blue-600"
                name="service"
                value="BotTradex_EA_V1 License"
                checked={formData.service === "BotTradex_EA_V1 License"}
                onChange={handleChange}
                required
              />
              <span className="ml-2">BotTradex_EA_V1 License</span>
            </label>
            <label className="mb-2 inline-flex items-center">
              <input
                type="radio"
                className="form-radio text-blue-600"
                name="service"
                value="BotTradex_HFT"
                checked={formData.service === "BotTradex_HFT"}
                onChange={handleChange}
                required
              />
              <span className="ml-2">BotTradex_HFT</span>
            </label>
            <label className="mb-2 inline-flex items-center">
              <input
                type="radio"
                className="form-radio text-blue-600"
                name="service"
                value="BotTradex_Scalp Club"
                checked={formData.service === "BotTradex_Scalp Club"}
                onChange={handleChange}
                required
              />
              <span className="ml-2">BotTradex_Scalp Club</span>
            </label>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="h-[60px] w-full rounded-lg bg-blue-600 px-4 py-2 font-bold text-white hover:bg-blue-700"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
