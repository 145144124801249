export const packages = [
  {
    plan: "Free Plan",
    cost: "AUD $0",
    subtext: "Beginners interested in automated trading",
    features: [
      "Basic BotTradeX EA License (limited functionality)",
      "Access to the BotTradeX community forum",
      "Regular updates and news on trading strategies",
    ],
    benefits: [
      "Get a feel for automated trading without any financial commitment",
      "Community Support: Engage with other traders in the BotTradeX community to share insights and gain knowledge.",
      "Stay Informed: Receive updates on trading strategies and market trends to enhance your trading knowledge.",
    ],
  },
  {
    plan: "Starter Package",
    cost: "AUD $9,990",
    subtext: "Novice traders",
    features: [
      "Lifetime BotTradeX EA License Pre-configured set files using BotTradeX's proven default strategy",
    ],
    benefits: [
      "Effortless integrate BotTradeX_EA_V1 into your MT4 trading platform.",
      "Gain hands-on experience with a reliable, pre-optimized strategy",
      "Establish a strong foundation for further exploration and customization",
      "BotTradeX_EA_V1 is engineered to achieve a monthly growth rate of 10%, though actual performance may deviate due to prevailing market conditions.",
    ],
  },
  {
    plan: "Premium Package",
    cost: "AUD $14,990",
    subtext: " Traders seeking to expand their strategies",
    features: [
      "Lifetime BotTradeX EA License 2x set files",
      "Default BotTradeX strategy",
      "3x confluence strategy for diversified market exposure",
      "3 months of priority customer support",
    ],
    benefits: [
      "All Starter Package benefits plus",
      "Explore diverse trading approaches with multiple pre-configured strategies",
      "Accelerate your learning curve and achieve consistent results",
    ],
  },
  {
    plan: "Elite Package",
    cost: "AUD $19,990",
    subtext: "Seasoned traders demanding peak performance",
    features: [
      "Lifetime BotTradeX EA License 3x set files",
      "Default BotTradeX strategy",
      "Custom strategy after a comprehensive optimization session",
      "6 months of priority customer support",
      "1x in-depth strategy optimization consultation with a BotTradeX expert",
    ],
    benefits: [
      "All Premium Package benefits plus:",
      "Harness the full power of BotTradeX with a personalized, optimized strategy",
      "Gain unparalleled insights and fine-tune your approach with expert guidance",
      "Maximize your profitability and unlock your full potential in the markets",
    ],
  },
];
export const customers = [
  {
    name: "Tochi D",
    description:
      "BotTradeX EAs have made trading so much easier for me. As a beginner, I was initially  intimidated, but the EA are user-friendly and the results are impressive. I'm really happy  with my decision to try them.",
  },
  {
    name: " Sarah L",
    description:
      "I've been trading for years, and BotTradeX's EAs have taken my strategies to the next  level. The algorithms are sophisticated, and the risk management features are solid. My  portfolio performance has improved significantly since using them.",
  },
  {
    name: "Daniel K",
    description:
      "I was skeptical at first, but the 14-day risk-free trial convinced me. I got to experience  the power of their EAs firsthand, and the results were undeniable. Now I'm a subscriber,  and I'm already seeing the benefits.",
  },
  {
    name: "Mia B",
    description:
      "BotTradeX EAs are decent, but they're not a magic bullet. I've had some wins and some  losses, which is expected in trading. I just think they're a good option for traders who  want to automate some of their strategies.",
  },
  {
    name: "Aisha H",
    description:
      "The BotTradeX team goes above and beyond for their customers. They are always  available to answer my questions and help me optimize my EAs. Their support is  top-notch, and I feel confident knowing they have my back",
  },
  {
    name: "Alexander P",
    description:
      "BotTradeX offers a wide range of EAs to suit different trading styles. I've been using  their momentum algorithms on TradingView, and they have consistently delivered solid  results. I'm impressed with their commitment to innovation and customer success.",
  },
];
export const faqs = [
  {
    title: "Can I try the software before purchasing?",
    content:
      "Yes, we offer a 14-day trial period to allow you to evaluate the software's functionality and features before making a purchase decision.",
  },
  {
    title: "Is the software compatible with Mac and PC?",
    content:
      "BotTradeX_EA_V1 operates primarily as an Expert Advisor (EA) within the MetaTrader 4 (MT4) platform. While designed for Windows, Mac users can access it via a Virtual Private Server (VPS) solution.",
  },
  {
    title: "On how many trading accounts can I use the software?",
    content:
      "Each license grants access to one live account and one demo account, facilitating strategy optimization and testing. For additional account requirements, please contact our support team.",
  },
  {
    title: "What type of file will I receive, and how do I install it?",
    content:
      "When you make a purchase, you will receive an .ex4 file. This file is in the Expert Advisor format compatible with MT4. Detailed instructions on how to install the file will be provided during your onboarding session and in the accompanying documentation.",
  },
  {
    title: "Do I need prior trading experience to use the software?",
    content:
      "Prior trading experience is not mandatory. We offer comprehensive tutorials, videos, and dedicated support to assist users of all levels. However, familiarity with trading concepts can enhance your ability to refine strategies and interpret market dynamics.",
  },
  {
    title: "What is your refund policy?",
    content:
      "Due to the digital nature of the product and the 14-day trial period, we maintain a strict no-refund policy, as outlined in our Terms and Conditions. We strongly encourage thorough evaluation during the trial and are committed to providing support to maximize the software's potential. Please note that trading involves inherent risks.",
  },
  {
    title: "Can I share the software with others?",
    content: `No, sharing the software is strictly prohibited. Each license is tied to a specific MT4 account. If you change your MT4 broker, contact us for license reassignment, which may incur fees. Unauthorized sharing violates intellectual property laws and is subject to legal action. If you have additional questions or require further assistance, please don't hesitate to reach out to our support team. help@bottradex.com  `,
  }
];
